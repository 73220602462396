<template>
    <div class="ml-10 mr-10">
        <TitleBar v-if="this.$route.params.uuid === 'new'" :loading="loading">{{ $t("categories.new") }}</TitleBar>
        <TitleBar
            v-else
            :disabled="loading"
            :loading="loading"
            :menu="[{text: this.$t('categories.delete'), callback: deleteElement, class: 'red--text'}]"
        >
            {{ $t("categories.edit") }}
            <v-chip
                v-if="default_category"
                class="ma-2"
                color="secondary"
                outlined
            >
                {{ $t("categories.default") }}
            </v-chip>
        </TitleBar>

        <v-form @submit.prevent="formSubmit">

            <v-row>
                <v-col md="9">

                    <Tags v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Tags>

                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="name"
                                :disabled="loading"
                                :error-messages="nameErrors"
                                :label="$t('categories.name')"
                                outlined
                                @blur="$v.name.$touch()"
                                @input="$v.name.$touch()"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="type"
                                :disabled="loading"
                                :error-messages="typeErrors"
                                :label="$t('categories.type')"
                                outlined
                                @blur="$v.type.$touch()"
                                @input="$v.type.$touch()"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="description"
                                :disabled="loading"
                                :label="$t('categories.description')"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>

                </v-col>
                <v-col md="3">
                    <v-color-picker
                        v-model="color"
                        :disabled="loading"
                        class="ma-2"
                        mode="hexa"
                        show-swatches
                    ></v-color-picker>
                </v-col>
            </v-row>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.uuid === "new" ? $t("categories.add") : $t("categories.update") }}
            </v-btn>
        </v-form>


        <!--        Fields -->

        <Attachments v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Attachments>

        <!--        <v-btn-->
        <!--            elevation="2"-->
        <!--            color="error"-->
        <!--            :loading="loading"-->
        <!--            @click.prevent="deleteElement"-->
        <!--            class="mt-10"-->
        <!--            v-if="this.$route.params.uuid !== 'new'"-->
        <!--        >{{ $t("categories.delete") }}-->
        <!--        </v-btn>-->

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Attachments from "../../components/Attachments";
import Utils from "../../helpers/Utils";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Details",
    components: {TitleBar, Attachments, Tags},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.categories"),
                to: "/categories",
                exact: true
            },
            {
                text: this.$route.params.uuid === "new" ? this.$t("categories.new") : this.$t("categories.edit"),
                to: "/categories/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.color = Utils.getColor(this, "primary");

        if (this.$route.params.uuid !== "new") {
            this.loading = true;
            Accounting.get("/categories/" + this.$route.params.uuid)
                .then(response => {
                    const data = response.data;
                    this.name = data.name;
                    this.type = data.type;
                    this.description = data.description;
                    this.color = data.color;
                    this.loading = false;

                    Accounting.get("/companies/" + this.$store.state.company.uuid + "/settings/default_category")
                        .then(resp => {
                            this.default_category = resp.data.value === this.$route.params.uuid;
                        }).finally(() => {
                    });
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }
    },
    data: () => {
        return {
            loading: false,
            name: null,
            type: null,
            color: "#1976d2",
            description: null,
            default_category: false
        };
    },
    methods: {
        deleteElement() {
            this.$swal({
                title: this.$t("categories.delete_title"),
                text: this.$t("categories.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("categories.delete_yes"),
                cancelButtonText: this.$t("categories.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/categories/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("categories.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/categories");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/categories";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    name: this.name,
                    color: this.color,
                    description: this.description,
                    type: this.type
                }).then(response => {

                    this.loading = false;
                    if (this.$route.params.uuid === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("categories.added")
                        }]);
                        this.$router.push("/categories/" + response.data.uuid);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("categories.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        name: {required},
        type: {required}
    },
    computed: {
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push(this.$t("categories.name_required"));
            return errors;
        },
        typeErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push(this.$t("categories.type_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>